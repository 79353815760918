import React, { useEffect } from 'react';
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate} from 'react-router-dom';

import './Mentions.scss';

const Mentions = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='mentions-container'>
            <div className='mentions-header'>
                <BsArrowLeftShort className='mentions-header-back-arrow' onClick={() => { navigate(-1) }} />
                <p className='mentions-header-text'>Mentions légales</p>
            </div>
            <p className='mentions-paragraph'>
            <br></br>En respect de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.
            </p>
            <p className='mentions-title'>1. Editeur du site</p>
            <p className='mentions-paragraph'>
                Le site internet www.enguerranhuby.fr appartient à Enguerran Huby.<br></br>
                Adresse : 53000 LAVAL<br></br>
                Email : enguerran.huby@aroed.fr
            </p>
            <p className='mentions-title'>2. Hébergement</p>
            <p className='mentions-paragraph'>
                Hébergeur : OVH<br></br>
                Adresse : 2 Rue Kellermann, 59100 Roubaix<br></br>
                Téléphone : 1007
            </p>
            <p className='mentions-title'>3. Créateur du site</p>
            <p className='mentions-paragraph'>
                Le développeur du site internet www.enguerranhuby.fr est Enguerran Huby.
            </p>
            <p className='mentions-title'>4. Reponsable des publications</p>
            <p className='mentions-paragraph'>
                Le responsable des publications est Enguerran Huby.
            </p>
            <p className='mentions-title'>5. Données personnelles</p>
            <p className='mentions-paragraph'>
                Aucune donnée personnelle n'est collectée par le site web, à l'exception des données de navigation à des fins de statistiques.<br></br>
                Aucun cookie n'est nécessaire à l'utilisation de ce dernier.
            </p>
        </div>

    );
};

export default Mentions;