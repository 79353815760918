const HOME_FR = require('../assets/translations/fr/home.json');
const HOME_EN = require('../assets/translations/en/home.json');
const HOME_RU = require('../assets/translations/ru/home.json');
const HOME_ES = require('../assets/translations/es/home.json');
const ABOUT_FR = require('../assets/translations/fr/about.json');
const ABOUT_EN = require('../assets/translations/en/about.json');
const ABOUT_RU = require('../assets/translations/ru/about.json');
const ABOUT_ES = require('../assets/translations/es/about.json');

const languages = {
    HOME_FR,
    HOME_EN,
    HOME_RU,
    HOME_ES,
    ABOUT_FR,
    ABOUT_EN,
    ABOUT_RU,
    ABOUT_ES,
};

export default languages;