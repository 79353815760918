import React, { useEffect } from 'react';
import { useParams } from "react-router";
import { pictures } from '../../constants';
import { BsArrowLeftShort, BsCodeSlash, BsFileText } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { IoLogoGooglePlaystore, IoCalendarClearOutline, IoSchoolOutline, IoNewspaperOutline } from "react-icons/io5";
import { HiOutlineDownload, HiStar } from "react-icons/hi";
import { AiOutlineMessage, AiOutlineTool, AiOutlineQrcode } from "react-icons/ai";
import { MdWorkspacesOutline } from "react-icons/md";
import { SiJava, SiFlutter } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { FaDatabase, FaReact } from "react-icons/fa";

import './Application.scss';

const apps = [
    {
        background: pictures.koozo_background,
        logo: pictures.koozo_logo,
        comments: "44",
        score: "4.841",
        downloads: "756",
        released: true,
        name: "Koozo - INSA Centre Val de Loire",
        description: 'Koozo est dédiée aux campus de Blois et de Bourges de l\'INSA Centre Val de Loire. Elle regroupe tous les outils et toutes les informations liées à la vie étudiante et à l\'école. Disponible en plusieurs langues, cette application est pour l\'heure non officielle.',
        playstore_url: "https://play.google.com/store/apps/details?id=captsquark.kaevann.koozo",
        date: "14 Janvier 2021",
        version: "2.3.3",
        categories: [
            {
                icon: <IoSchoolOutline className='application-content-left-categories-category-icon' />,
                name: "Enseignement",
            },
            {
                icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                name: "Outils",
            },
            {
                icon: <IoNewspaperOutline className='application-content-left-categories-category-icon' />,
                name: "Informations",
            },
        ],
        languages: [
            {
                icon: <SiJava className='application-content-left-categories-category-icon' />,
                name: "Java",
            },
            {
                icon: <BsFileText className='application-content-left-categories-category-icon' />,
                name: "Xml",
            },
        ],
        captures: [
            {
                image: pictures.koozo_capture_one,
            },
            {
                image: pictures.koozo_capture_two,
            },
        ]
    },
    {
        background: pictures.pdf_qr_scanner_background,
        logo: pictures.pdf_qr_scanner_logo,
        downloads: "1279",
        score: "4.750",
        comments: "4",
        released: true,
        name: "PDF QR Scanner",
        description: 'Application développée pendant le premier confinement afin de rendre accessible les QR Code à tout le monde. Il suffit de scanner un QR Code et l\'application lira automatiquement le fichier PDF grâce au lecteur intégré. Pas besoin de cliquer sur un lien ou d\'avoir une application externe.',
        playstore_url: "https://play.google.com/store/apps/details?id=fr.aroed.scanerpdfqrcode",
        date: "27 Mai 2020",
        version: "1.0.1",
        languages: [
            {
                icon: <SiFlutter className='application-content-left-categories-category-icon' />,
                name: "Flutter",
            },
            {
                icon: <BsCodeSlash className='application-content-left-categories-category-icon' />,
                name: "Dart",
            },
        ],
        categories: [
            {
                icon: <MdWorkspacesOutline className='application-content-left-categories-category-icon' />,
                name: "Productivité",
            },
            {
                icon: <AiOutlineQrcode className='application-content-left-categories-category-icon' />,
                name: "QR Code",
            },
            {
                icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                name: "Outils",
            },
        ],
        captures: [
            {
                image: pictures.pdf_qr_scanner_capture_one,
            },
            {
                image: pictures.pdf_qr_scanner_capture_two,
            },
            {
                image: pictures.pdf_qr_scanner_capture_three,
            }
        ]
    },
    {
        background: pictures.arvann_background,
        logo: pictures.arvann_logo,
        downloads: undefined,
        released: false,
        comments: undefined,
        score: undefined,
        name: "Arvann - Revise Yourself",
        description: 'Arvann est dédiée aux révisions scolaires. Enregistrez vos cours, relisez les autant de fois que nécessaire et répondez à des questions posées sur les cours que vous avez enregistrés. Ne nécessite aucune connexion internet. Projet en cours de développement.',
        playstore_url: undefined,
        date: undefined,
        version: undefined,
        languages: [
            {
                icon: <FaReact className='application-content-left-categories-category-icon' />,
                name: "React Native",
            },
            {
                icon: <FaDatabase className='application-content-left-categories-category-icon' />,
                name: "SQLite",
            },
            {
                icon: <IoLogoJavascript className='application-content-left-categories-category-icon' />,
                name: "Javascript",
            },
        ],
        categories: [
            {
                icon: <IoSchoolOutline className='application-content-left-categories-category-icon' />,
                name: "Enseignement",
            },
            {
                icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                name: "Outils",
            },
        ],
        captures: []
    },
];

const Application = () => {
    const navigate = useNavigate();
    const params = useParams();
    let app = undefined;
    const isFound = apps.some(element => {
        if (element.name === params.name) {
            app = element;
            return true;
        }
        return false;
    });
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    if (isFound)
        return (
            <div className='application-container'>
                <div className='application-topbar'>
                    <div className='application-topbar-left'>
                        <BsArrowLeftShort className='application-topbar-back-arrow' onClick={() => { navigate(-1) }} />
                        <div className='application-topbar-text'>
                            <p className='application-topbar-title'>{app.name}</p>
                            {app.released && (<p className='application-topbar-subtitle'>Disponible sur Android</p>)}
                            {!app.released && (<p className='application-topbar-subtitle'>Indisponible</p>)}
                        </div>
                    </div>
                    <div className='application-topbar-shape-back'>
                        <img className='application-topbar-shape-back-logo' src={app.logo} alt='appcard-logo' />
                    </div>
                </div>

                <div className='application-content'>
                    <div className='application-content-left'>
                        {app.released && (<div className='application-content-left-title'>Télécharger</div>)}
                        {app.released && (<div className='application-content-left-downloads'>
                            <a href={app.playstore_url} target="_blank" rel="noreferrer" className='application-content-left-downloads-button'>
                                <IoLogoGooglePlaystore className='application-content-left-downloads-button-icon' />
                                <p className='application-content-left-downloads-button-text'>Play Store</p>
                            </a>
                        </div>)}

                        <div className='application-content-left-title'>Description</div>
                        <p className='application-content-left-description'>{app.description}</p>

                        <div className='application-content-left-title'>Catégories</div>
                        <div className='application-content-left-categories'>
                            {app.categories.map((category: any, index: number) => (
                                <div className='application-content-left-categories-category' key={index}>
                                    {category.icon}
                                    <p className='application-content-left-categories-category-text'>{category.name}</p>
                                </div>
                            ))}
                        </div>


                        <div className='application-content-left-title'>Langages</div>
                        <div className='application-content-left-categories'>
                            {app.languages.map((language: any, index: number) => (
                                <div key={index} className='application-content-left-categories-category'>
                                    {language.icon}
                                    <p className='application-content-left-categories-category-text'>{language.name}</p>
                                </div>
                            ))}
                        </div>

                        {app.released && (<div className='application-content-left-title'>Captures</div>)}
                    </div>

                    <div className='application-content-right'>
                        {app.released && (<div className='application-content-right-stats'>
                            <p className='application-content-right-stats-text'>{app.date}</p>
                            <IoCalendarClearOutline className='application-content-right-stats-icon' />
                        </div>)}
                        {app.released && (<div className='application-content-right-stats'>
                            <p className='application-content-right-stats-text'>{app.version}</p>
                            <BsCodeSlash className='application-content-right-stats-icon' />
                        </div>)}
                        {app.released && (<div className='application-content-right-stats'>
                            <p className='application-content-right-stats-text'>{app.downloads}</p>
                            <HiOutlineDownload className='application-content-right-stats-icon' />
                        </div>)}
                        {app.released && (<div className='application-content-right-stats'>
                            <p className='application-content-right-stats-text'>{app.comments}</p>
                            <AiOutlineMessage className='application-content-right-stats-icon' />
                        </div>)}
                        {app.released && (<div className='application-content-right-stats'>
                            <p className='application-content-right-stats-text'>{app.score}</p>
                            <HiStar className='application-content-right-stats-icon' />
                        </div>)}
                    </div>
                </div>

                <div className='application-captures'>
                    {app.captures.map((capture: any, index: number) => (
                        <div key={index}>
                            <img src={capture.image} className='application-capture-img' alt={'capture_'+{index}}></img>
                        </div>
                    ))}
                </div>

            </div>
        );
    else {
        window.location.replace("/error");
    }
}

export default Application;