import React, { useEffect } from 'react';

import './Error404.scss';

const Error404 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='error404-container'>
            <p className='error404-explain'>ERROR</p>
            <p className='error404-text'>404</p>
            <p className='error404-subtext'>PAGE NOT FOUND</p>
            <p className='error404-explain'>The page you are looking for does not exist</p>
        </div>
    );
};

export default Error404;