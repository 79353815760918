import React, { useEffect } from 'react';

import './Blog.scss';

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='blog-container'>
            <p className='blog-title'>Blog</p>
            <div className='blog-miss'>
                <p>Aucun projet trouvé</p>
            </div>
        </div>
    );
};

export default Blog;