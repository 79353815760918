import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Home.scss';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { t } = useTranslation('home');

    return (
        <div className='home-container'>
            <div className='home-message-central'>
                <div className='home-center'>
                    <p className='home-hello'>{t('hello1')}<br></br>{t('hello2')},<span className='home-and'>{t('and')}</span></p>
                </div>
                <p className='home-welcome'>{t('welcome')}</p>
            </div>
        </div>

    );
};

export default Home;