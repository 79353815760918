import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languages } from './constants';

const resources = {
    fr: {
        home: languages.HOME_FR,
        about: languages.ABOUT_FR,
    },
    en: {
        home: languages.HOME_EN,
        about: languages.ABOUT_EN,
    },
    ru: {
        home: languages.HOME_RU,
        about: languages.ABOUT_RU,
    },
    es: {
        home: languages.HOME_ES,
        about: languages.ABOUT_ES,
    }
};

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;