const koozo_background = require('../assets/pictures/apps/koozo/background.jpeg')
const koozo_logo = require('../assets/pictures/apps/koozo/logo.png')
const pdf_qr_scanner_logo = require('../assets/pictures/apps/pdf_qr_code_scanner/logo.png')
const arvann_logo = require('../assets/pictures/apps/arvann/logo.png')
const pdf_qr_scanner_background = require('../assets/pictures/apps/pdf_qr_code_scanner/background.jpeg')
const arvann_background = require('../assets/pictures/apps/arvann/background.jpeg')
const pdf_qr_scanner_capture_one = require('../assets/pictures/apps/pdf_qr_code_scanner/capture_one.jpg')
const pdf_qr_scanner_capture_two = require('../assets/pictures/apps/pdf_qr_code_scanner/capture_two.jpg')
const pdf_qr_scanner_capture_three = require('../assets/pictures/apps/pdf_qr_code_scanner/capture_three.jpg')
const koozo_capture_one = require('../assets/pictures/apps/koozo/capture_one.jpg')
const koozo_capture_two = require('../assets/pictures/apps/koozo/capture_two.jpg')

const Altitude = require('../assets/pictures/instagram/Altitude.jpg')
const Couronne = require('../assets/pictures/instagram/Couronne.jpg')
const Evasion = require('../assets/pictures/instagram/Evasion.jpg')
const Finlande = require('../assets/pictures/instagram/Finlande.jpg')
const Légèreté = require('../assets/pictures/instagram/Legerete.jpg')
const Méduse = require('../assets/pictures/instagram/Meduse.jpg')
const Paris = require('../assets/pictures/instagram/Paris.jpg')
const Partage = require('../assets/pictures/instagram/Partage.jpg')
const Rap = require('../assets/pictures/instagram/Rap.jpg')
const Saint_Petersbourg = require('../assets/pictures/instagram/Saint-Petersbourg.jpg')
const twloha = require('../assets/pictures/instagram/twloha.jpg')
const Venise = require("../assets/pictures/instagram/Venise.jpg")

const profile = require("../assets/pictures/profile.jpg")
const french_flag = require('../assets/pictures/flags/french_flag.png')
const russian_flag = require('../assets/pictures/flags/russian_flag.png')
const royaume_uni_flag = require('../assets/pictures/flags/royaumeuni_flag.png')
const english_flag = require('../assets/pictures/flags/english_flag.png')
const spainish_flag = require('../assets/pictures/flags/spainish_flag.png')
const albanie_flag = require('../assets/pictures/flags/albanie_flag.png')
const hungary_flag = require('../assets/pictures/flags/hungary_flag.png')
const finland_flag = require('../assets/pictures/flags/finland_flag.png')
const usa_flag = require('../assets/pictures/flags/usa_flag.png')
const estonie_flag = require('../assets/pictures/flags/estonian_flag.png')


const pictures = {
    koozo_background,
    koozo_logo,
    pdf_qr_scanner_logo,
    arvann_logo,
    pdf_qr_scanner_background,
    arvann_background,
    Altitude,
    Couronne,
    Evasion,
    Finlande,
    Légèreté,
    Méduse,
    Paris,
    Partage,
    Rap,
    Saint_Petersbourg,
    twloha,
    Venise,
    profile,
    french_flag,
    russian_flag,
    royaume_uni_flag,
    english_flag,
    spainish_flag,
    albanie_flag,
    hungary_flag,
    finland_flag,
    usa_flag,
    estonie_flag,
    pdf_qr_scanner_capture_one,
    pdf_qr_scanner_capture_two,
    pdf_qr_scanner_capture_three,
    koozo_capture_one,
    koozo_capture_two,
};

export default pictures;