import React from 'react';
import './Footer.scss';
import { FaInstagram, FaLinkedinIn, FaGithub, FaGooglePlay } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className="footer">
      <p>Copyright © 2022 · Enguerran Huby</p>
      <form className='footer-newsletter'>
        <input className='footer-newsletter-input' placeholder="Get updates (Email)" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required></input>
        <button className='footer-newsletter-button' type="button">Subscribe</button>
  </form>
      <div className='footer-logos'>
        <FaInstagram className='footer-logo' />
        <FaLinkedinIn className='footer-logo' />
        <FaGithub className='footer-logo' />
        <FaGooglePlay className='footer-logo' />
      </div>
      <Link to={`/mentions`} className="footer-mentions">Mentions légales</Link>
    </footer>
  );
};

export default Footer;