import React, { useEffect } from 'react';
import { pictures } from '../../constants';
import './About.scss';
import { FaInstagram, FaLinkedinIn, FaGithub, FaGooglePlay, FaDatabase, FaBeer, FaRunning } from "react-icons/fa";
import { IoEarthSharp, IoCalendarClearOutline } from "react-icons/io5";
import { IoLogoJavascript, IoMdPhotos, } from "react-icons/io";
import { MdOutlineHome, MdOutlineWeb, MdOutlineEmail, MdPersonOutline, MdOutlineLocationOn, MdFormatPaint, MdHearing, MdOutlineFileDownloadDone, MdDesignServices, MdPerson, MdVideogameAsset } from "react-icons/md";
import { BsCodeSlash } from "react-icons/bs";
import { SiJava, SiPython, SiHtml5, SiFlutter, SiPhp } from "react-icons/si";
import { GiNetworkBars, GiCook } from 'react-icons/gi';
import { AiOutlineCar } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { t } = useTranslation('about');

    const details = [
        {
            icon: <MdPersonOutline className='about-profile-details-list-icon' />,
            text: t('age'),
        },
        {
            icon: <MdOutlineEmail className='about-profile-details-list-icon' />,
            text: "enguerran.huby@aroed.fr",
        },
        {
            icon: <MdOutlineWeb className='about-profile-details-list-icon' />,
            text: "www.enguerranhuby.fr",
        },
        {
            icon: <MdOutlineHome className='about-profile-details-list-icon' />,
            text: t('adress'),
        },
        {
            icon: <AiOutlineCar className='about-profile-details-list-icon' />,
            text: t('licence'),
        }
    ];

    const cvs = [
        {
            picture: pictures.french_flag,
            language: t('french'),
        },
        {
            picture: pictures.royaume_uni_flag,
            language: t('english'),
        },
        {
            picture: pictures.russian_flag,
            language: t('russian'),
        },
        {
            picture: pictures.spainish_flag,
            language: t('spanish'),
        },
    ]

    const langages = [
        {
            picture: pictures.french_flag,
            language: t('french'),
            level: t('native_lang'),
        },
        {
            picture: pictures.royaume_uni_flag,
            language: t('english'),
            level: t('toeic'),
        },
        {
            picture: pictures.russian_flag,
            language: t('russian'),
            level: t('beginner'),
        },
        {
            picture: pictures.spainish_flag,
            language: t('spanish'),
            level: t('advanced'),
        },
    ]

    const schools = [
        {
            name: t('esiea'),
            description: t('esiea_content'),
            dates: t('esiea_date'),
            adress: t('esiea_place'),
            weburl: "https://www.esiea.fr/",
        },
        {
            name: t('ucpori'),
            description: t('ucpori_content'),
            dates: t('ucpori_date'),
            adress: t('ucpori_place'),
            weburl: "https://ucpori.fi/en-gb",
        },
        {
            name: t('insa'),
            description: t('insa_content'),
            dates: t('insa_date'),
            adress: t('insa_place'),
            weburl: "https://www.insa-centrevaldeloire.fr/fr/",
        },
        {
            name: t('saintemarie'),
            description: t('saintemarie_content'),
            dates: t('saintemarie_date'),
            adress: t('saintemarie_place'),
            weburl: "https://www.saintemarie-saintpierre-caen.fr/lycee/",
        },
    ]

    const jobs = [
        {
            name: t('numidev'),
            description: t('numidev_content'),
            dates: t('numidev_date'),
            adress: t('numidev_place'),
            weburl: "https://www.numidev.fr/",
        },
        {
            name: t('adecco'),
            description: t('adecco_content'),
            dates: t('adecco_date'),
            adress: t('adecco_place'),
            weburl: "https://www.adecco.fr/",
        },
        {
            name: t('tartefrais'),
            description: t('tartefrais_content'),
            dates: t('tartefrais_date'),
            adress: t('tartefrais_place'),
            weburl: "https://www.tartefrais.com/",
        },
    ]

    const tech_skills = [
        {
            icon: <BsCodeSlash className='about-content-skills-content-skill-icon' />,
            name: "C/C++",
        },
        {
            icon: <SiHtml5 className='about-content-skills-content-skill-icon' />,
            name: "HTML/CSS",
        },
        {
            icon: <SiJava className='about-content-skills-content-skill-icon' />,
            name: "JAVA/XML",
        },
        {
            icon: <SiPython className='about-content-skills-content-skill-icon' />,
            name: "Python",
        },
        {
            icon: <GiNetworkBars className='about-content-skills-content-skill-icon' />,
            name: "Réseau IPv4",
        },
        {
            icon: <FaDatabase className='about-content-skills-content-skill-icon' />,
            name: "SQL",
        },
        {
            icon: <SiFlutter className='about-content-skills-content-skill-icon' />,
            name: "Flutter",
        },
        {
            icon: <IoLogoJavascript className='about-content-skills-content-skill-icon' />,
            name: "Javascript (React, Vue.js)",
        },
        {
            icon: <SiPhp className='about-content-skills-content-skill-icon' />,
            name: "PHP (Laravel, Symfony)",
        },
    ]

    const soft_skills = [
        {
            icon: <MdPerson className='about-content-skills-content-skill-icon' />,
            name: t('autonomous'),
        },
        {
            icon: <MdFormatPaint className='about-content-skills-content-skill-icon' />,
            name: t('creativity'),
        },
        {
            icon: <MdHearing className='about-content-skills-content-skill-icon' />,
            name: t('listen'),
        },
        {
            icon: <MdOutlineFileDownloadDone className='about-content-skills-content-skill-icon' />,
            name: t('rigourous'),
        },
    ]

    const eu_countries = [
        {
            icon: <img src={pictures.french_flag} className='about-content-skills-content-skill-icon' alt='france' />,
            name: t('france'),
        },
        {
            icon: <img src={pictures.english_flag} className='about-content-skills-content-skill-icon' alt='england' />,
            name: t('england'),
        },
        {
            icon: <img src={pictures.spainish_flag} className='about-content-skills-content-skill-icon' alt='spain' />,
            name: t('spain'),
        },
        {
            icon: <img src={pictures.finland_flag} className='about-content-skills-content-skill-icon' alt='finland' />,
            name: t('finland'),
        },
        {
            icon: <img src={pictures.albanie_flag} className='about-content-skills-content-skill-icon' alt='albania' />,
            name: t('albania'),
        },
        {
            icon: <img src={pictures.estonie_flag} className='about-content-skills-content-skill-icon' alt='estonia' />,
            name: t('estonia'),
        },
        {
            icon: <img src={pictures.hungary_flag} className='about-content-skills-content-skill-icon' alt='hungary' />,
            name: t('hungary'),
        },
    ]

    const na_countries = [
        {
            icon: <img src={pictures.usa_flag} className='about-content-skills-content-skill-icon' alt='usa' />,
            name: t('united_states'),
        },
    ]

    const hobbies = [
        {
            icon: <FaRunning className='about-content-skills-content-skill-icon' />,
            name: t('running'),
        },
        {
            icon: <IoEarthSharp className='about-content-skills-content-skill-icon' />,
            name: t('trips'),
        },
        {
            icon: <IoMdPhotos className='about-content-skills-content-skill-icon' />,
            name: t('photomontage'),
        },
        {
            icon: <MdDesignServices className='about-content-skills-content-skill-icon' />,
            name: t('design'),
        },
        {
            icon: <MdVideogameAsset className='about-content-skills-content-skill-icon' />,
            name: t('videogames'),
        },
        {
            icon: <GiCook className='about-content-skills-content-skill-icon' />,
            name: t('cooking'),
        },
        {
            icon: <BsCodeSlash className='about-content-skills-content-skill-icon' />,
            name: t('computing'),
        },
        {
            icon: <FaBeer className='about-content-skills-content-skill-icon' />,
            name: t('parties'),
        },
    ]

    return (
        <div className='about-container'>
            <p className='about-title'>About</p>
            <div className='about-content'>
                <div className='about-content-left'>
                    <img className='about-profile-picture' src={pictures.profile} alt='about-profile' />
                    <p className='about-profile-name'>{t('enguerran_huby')}</p>
                    <p className='about-profile-details'>{t('esiea_student')}</p>
                    <p className='about-profile-details'>{t('fith_year')}</p>
                    <p className='about-profile-details bottom-15'>{t('swe')}</p>
                    <div className='about-profile-details-list-container'>
                        {details.map((detail, index) => (
                            <div className='about-profile-details-list' key={index}>
                                {detail.icon}
                                <p className='about-profile-details-list-text'>{detail.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className='about-profile-logos'>
                        <FaInstagram className='about-profile-logo' />
                        <FaLinkedinIn className='about-profile-logo' />
                        <FaGithub className='about-profile-logo' />
                        <FaGooglePlay className='about-profile-logo' />
                    </div>
                    <div className='about-profile-languages-container'>
                        {langages.map((langage, index) => (
                            <div className='about-profile-languages' key={index}>
                                <img className='about-profile-languages-flag' src={langage.picture} alt='langage-flag' />
                                <div className='about-profile-languages-texts'>
                                    <p className='about-profile-languages-language'>{langage.language}</p>
                                    <p className='about-profile-languages-level'>{langage.level}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div className='about-content-right'>
                    <p className='about-content-right-title'>{t('cv')}</p>
                    <div className='about-cv-downloads'>
                        {cvs.map((cv, index) => (
                            <div className='about-cv-download' key={index}>
                                <div className='about-cv-download-lang'>
                                    <p className='about-cv-download-text'>{cv.language}</p>
                                    <img className='about-cv-download-flag' src={cv.picture} alt='appcard-logo' />
                                </div>
                                <div className='about-cv-download-button'>
                                    {t('download')}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className='about-content-right-title'>{t('presentation')}</p>
                    <p className='about-content-right-paragraph'>
                        {t('presentation_content')}
                    </p>
                    <p className='about-content-right-title'>{t('exp_pro')}</p>
                    {jobs.map((job, index) => (
                        <div className='about-content-formations' key={index}>
                            <div className='about-content-formations-left'>
                                <p className='about-content-formations-text'>{job.name}</p>
                                <a className='about-content-formations-button' href={job.weburl} target="_blank" rel="noreferrer">
                                    {t('visit')}
                                </a>
                            </div>
                            <div className='about-content-formations-right'>
                                <div className='about-content-formations-details'>
                                    <div className='about-content-formations-detail'>
                                        <IoCalendarClearOutline className='about-content-formations-icon' />
                                        <p className='about-content-formations-text'>{job.dates}</p>
                                    </div>
                                    <div className='about-content-formations-detail'>
                                        <MdOutlineLocationOn className='about-content-formations-icon' />
                                        <p className='about-content-formations-text'>{job.adress}</p>
                                    </div>
                                </div>
                                <p className='about-content-formation-description'>
                                    {job.description}
                                </p>
                            </div>
                        </div>
                    ))}
                    <p className='about-content-right-title'>{t('skills')}</p>
                    <div className='about-content-skills-container'>
                        <div className='about-content-skills-title'>
                            <p className='about-content-skills-title-line'></p>
                            <p className='about-content-skills-title-text'>{t('hard_skills')}</p>
                            <p className='about-content-skills-title-line'></p>
                        </div>
                        <div className='about-content-skills-content'>
                            {tech_skills.sort((a, b) => a.name > b.name ? 1 : -1).map((tech_skill, index) => (
                                <div key={index} className='about-content-skills-content-skill'>
                                    {tech_skill.icon}
                                    <p className='about-content-skills-content-skill-text'>{tech_skill.name}</p>
                                </div>
                            ))}
                        </div>
                        <div className='about-content-skills-title'>
                            <p className='about-content-skills-title-line'></p>
                            <p className='about-content-skills-title-text'>{t('soft_skills')}</p>
                            <p className='about-content-skills-title-line'></p>
                        </div>
                        <div className='about-content-skills-content'>
                            {soft_skills.sort((a, b) => a.name > b.name ? 1 : -1).map((tech_skill, index) => (
                                <div key={index} className='about-content-skills-content-skill'>
                                    {tech_skill.icon}
                                    <p className='about-content-skills-content-skill-text'>{tech_skill.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className='about-content-right-title'>{t('formation')}</p>
                    {schools.map((school, index) => (
                        <div className='about-content-formations' key={index}>
                            <div className='about-content-formations-left'>
                                <p className='about-content-formations-text'>{school.name}</p>
                                <a className='about-content-formations-button' href={school.weburl} target="_blank" rel="noreferrer">
                                    {t('visit')}
                                </a>
                            </div>
                            <div className='about-content-formations-right'>
                                <div className='about-content-formations-details'>
                                    <div className='about-content-formations-detail'>
                                        <IoCalendarClearOutline className='about-content-formations-icon' />
                                        <p className='about-content-formations-text'>{school.dates}</p>
                                    </div>
                                    <div className='about-content-formations-detail'>
                                        <MdOutlineLocationOn className='about-content-formations-icon' />
                                        <p className='about-content-formations-text'>{school.adress}</p>
                                    </div>
                                </div>
                                <p className='about-content-formation-description'>
                                    {school.description}
                                </p>
                            </div>
                        </div>
                    ))}
                    <p className='about-content-right-title'>{t('visited_countries')}</p>
                    <div className='about-content-skills-container'>
                        <div className='about-content-skills-title'>
                            <p className='about-content-skills-title-line'></p>
                            <p className='about-content-skills-title-text'>{t('europe')}</p>
                            <p className='about-content-skills-title-line'></p>
                        </div>
                        <div className='about-content-skills-content'>
                            {eu_countries.sort((a, b) => a.name > b.name ? 1 : -1).map((eu_country, index) => (
                                <div key={index} className='about-content-skills-content-skill'>
                                    {eu_country.icon}
                                    <p className='about-content-skills-content-skill-text'>{eu_country.name}</p>
                                </div>
                            ))}
                        </div>
                        <div className='about-content-skills-title'>
                            <p className='about-content-skills-title-line'></p>
                            <p className='about-content-skills-title-text'>{t('america')}</p>
                            <p className='about-content-skills-title-line'></p>
                        </div>
                        <div className='about-content-skills-content'>
                            {na_countries.sort((a, b) => a.name > b.name ? 1 : -1).map((na_coutry, index) => (
                                <div key={index} className='about-content-skills-content-skill'>
                                    {na_coutry.icon}
                                    <p className='about-content-skills-content-skill-text'>{na_coutry.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className='about-content-right-title'>{t('interests')}</p>
                    <div className='about-content-skills-container'>
                        <div className='about-content-skills-title'>
                            <p className='about-content-skills-title-line'></p>
                            <p className='about-content-skills-title-text'>{t('hobbies')}</p>
                            <p className='about-content-skills-title-line'></p>
                        </div>
                        <div className='about-content-skills-content'>
                            {hobbies.sort((a, b) => a.name > b.name ? 1 : -1).map((hobby, index) => (
                                <div key={index} className='about-content-skills-content-skill'>
                                    {hobby.icon}
                                    <p className='about-content-skills-content-skill-text'>{hobby.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default About;