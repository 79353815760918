import React, { useState, useEffect } from 'react';
import AppCard from './AppCard';
import ArtCard from './ArtCard';
import { BsFilter, BsCheck, BsCodeSlash, BsFileText } from "react-icons/bs";
import { pictures } from '../../constants';
import { IoSchoolOutline, IoNewspaperOutline } from "react-icons/io5";
import { AiOutlineTool, AiOutlineQrcode } from "react-icons/ai";
import { MdWorkspacesOutline } from "react-icons/md";
import { SiJava, SiFlutter } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { FaDatabase, FaReact } from "react-icons/fa";

import './Projects.scss';

const Projects = () => {

    const [mode, setMode] = useState(1);
    const [filter, setFilter] = useState(false);
    const [filterMode, setFilterMode] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const apps = [
        {
            background: pictures.koozo_background,
            logo: pictures.koozo_logo,
            comments: "44",
            score: "4.841",
            downloads: "756",
            released: true,
            name: "Koozo - INSA Centre Val de Loire",
            description: 'Koozo est dédiée aux campus de Blois et de Bourges de l\'INSA Centre Val de Loire. Elle regroupe tous les outils et toutes les informations liées à la vie étudiante et à l\'école. Disponible en plusieurs langues, cette application est pour l\'heure non officielle.',
            playstore_url: "https://play.google.com/store/apps/details?id=captsquark.kaevann.koozo",
            date: "14 Janvier 2021",
            version: "2.3.3",
            categories: [
                {
                    icon: <IoSchoolOutline className='application-content-left-categories-category-icon' />,
                    name: "Enseignement",
                },
                {
                    icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                    name: "Outils",
                },
                {
                    icon: <IoNewspaperOutline className='application-content-left-categories-category-icon' />,
                    name: "Informations",
                },
            ],
            languages: [
                {
                    icon: <SiJava className='application-content-left-categories-category-icon' />,
                    name: "Java",
                },
                {
                    icon: <BsFileText className='application-content-left-categories-category-icon' />,
                    name: "Xml",
                },
            ],
            captures: [
                {
                    image: pictures.koozo_capture_one,
                },
                {
                    image: pictures.koozo_capture_two,
                },
            ]
        },
        {
            background: pictures.pdf_qr_scanner_background,
            logo: pictures.pdf_qr_scanner_logo,
            downloads: "1279",
            score: "4.750",
            comments: "4",
            released: true,
            name: "PDF QR Scanner",
            description: 'Application développée pendant le premier confinement afin de rendre accessible les QR Code à tout le monde. Il suffit de scanner un QR Code et l\'application lira automatiquement le fichier PDF grâce au lecteur intégré. Pas besoin de cliquer sur un lien ou d\'avoir une application externe.',
            playstore_url: "https://play.google.com/store/apps/details?id=fr.aroed.scanerpdfqrcode",
            date: "27 Mai 2020",
            version: "1.0.1",
            languages: [
                {
                    icon: <SiFlutter className='application-content-left-categories-category-icon' />,
                    name: "Flutter",
                },
                {
                    icon: <BsCodeSlash className='application-content-left-categories-category-icon' />,
                    name: "Dart",
                },
            ],
            categories: [
                {
                    icon: <MdWorkspacesOutline className='application-content-left-categories-category-icon' />,
                    name: "Productivité",
                },
                {
                    icon: <AiOutlineQrcode className='application-content-left-categories-category-icon' />,
                    name: "QR Code",
                },
                {
                    icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                    name: "Outils",
                },
            ],
            captures: [
                {
                    image: pictures.pdf_qr_scanner_capture_one,
                },
                {
                    image: pictures.pdf_qr_scanner_capture_two,
                },
                {
                    image: pictures.pdf_qr_scanner_capture_three,
                }
            ]
        },
        {
            background: pictures.arvann_background,
            logo: pictures.arvann_logo,
            downloads: undefined,
            released: false,
            comments: undefined,
            score: undefined,
            name: "Arvann - Revise Yourself",
            description: 'Arvann est dédiée aux révisions scolaires. Enregistrez vos cours, relisez les autant de fois que nécessaire et répondez à des questions posées sur les cours que vous avez enregistrés. Ne nécessite aucune connexion internet. Projet en cours de développement.',
            playstore_url: undefined,
            date: undefined,
            version: undefined,
            languages: [
                {
                    icon: <FaReact className='application-content-left-categories-category-icon' />,
                    name: "React Native",
                },
                {
                    icon: <FaDatabase className='application-content-left-categories-category-icon' />,
                    name: "SQLite",
                },
                {
                    icon: <IoLogoJavascript className='application-content-left-categories-category-icon' />,
                    name: "Javascript",
                },
            ],
            categories: [
                {
                    icon: <IoSchoolOutline className='application-content-left-categories-category-icon' />,
                    name: "Enseignement",
                },
                {
                    icon: <AiOutlineTool className='application-content-left-categories-category-icon' />,
                    name: "Outils",
                },
            ],
            captures: []
        },
    ];

    const arts = [
        {
            picture: pictures.Altitude,
            title: 'Altitude',
            instagram_link: 'https://www.instagram.com/p/CDjHoHmiYMG/',
            date: "6 Août 2020",
        },
        {
            picture: pictures.Couronne,
            title: 'Couronne',
            instagram_link: 'https://www.instagram.com/p/CZE7duWMgPE/',
            date: "23 Janvier 2022",
        },
        {
            picture: pictures.Evasion,
            title: 'Evasion',
            instagram_link: 'https://www.instagram.com/p/CDYydSviiAu/',
            date: "2 Août 2020",
        },
        {
            picture: pictures.Finlande,
            title: 'Finlande',
            instagram_link: 'https://www.instagram.com/p/CEM-kVTikqF/',
            date: "22 Août 2020",
        },
        {
            picture: pictures.Légèreté,
            title: 'Légèreté',
            instagram_link: 'https://www.instagram.com/p/CDoRlDoCdyx/',
            date: "8 Août 2020",
        },
        {
            picture: pictures.Méduse,
            title: 'Méduse',
            instagram_link: 'https://www.instagram.com/p/CDbZncBCwTG/',
            date: "3 Août 2020",
        },
        {
            picture: pictures.Paris,
            title: 'Paris',
            instagram_link: 'https://www.instagram.com/p/CDlrgNli1KA/',
            date: "7 Août 2020",
        },
        {
            picture: pictures.Partage,
            title: 'Partage',
            instagram_link: 'https://www.instagram.com/p/CG-4AnWL3_b/',
            date: "30 Octobre 2020",
        },
        {
            picture: pictures.Rap,
            title: 'Rap',
            instagram_link: 'https://www.instagram.com/p/CEXHsA5C-GE/',
            date: "26 Août 2020",
        },
        {
            picture: pictures.Saint_Petersbourg,
            title: 'Saint-Petersbourg',
            instagram_link: 'https://www.instagram.com/p/CDgkGH5izU_/',
            date: "5 Août 2020",
        },
        {
            picture: pictures.twloha,
            title: 'TWLOHA',
            instagram_link: 'https://www.instagram.com/p/CE-GrxyKfRg/',
            date: "10 Septembre 2020",
        },
        {
            picture: pictures.Venise,
            title: 'Venise',
            instagram_link: 'https://www.instagram.com/p/CDeAaK6C0Uf/',
            date: "4 Août 2020",
        },
    ];

    const categories = [
        {
            name: "Websites",
        },
        {
            name: "Applications",
        },
        {
            name: "Arts",
        },
        {
            name: "Others",
        }
    ];

    const filters = [
        {
            name: "Titre croissant",
        },
        {
            name: "Titre décroissant",
        },
        {
            name: "Date croissante",
        },
        {
            name: "Date décroissante",
        }
    ];

    // Convert STRING into DATE
    const convertStringToDate = (dateString: string) => {
        if (dateString === undefined) dateString = "01 Janvier 1900";
        const day = dateString.split(' ')[0];
        const year = dateString.split(' ')[2];
        let month = 1;
        if (dateString.split(' ')[1] === 'Février') month = 2;
        else if (dateString.split(' ')[1] === 'Mars') month = 3;
        else if (dateString.split(' ')[1] === 'Avril') month = 4;
        else if (dateString.split(' ')[1] === 'Mai') month = 5;
        else if (dateString.split(' ')[1] === 'Juin') month = 6;
        else if (dateString.split(' ')[1] === 'Juillet') month = 7;
        else if (dateString.split(' ')[1] === 'Août') month = 8;
        else if (dateString.split(' ')[1] === 'Septembre') month = 9;
        else if (dateString.split(' ')[1] === 'Octobre') month = 10;
        else if (dateString.split(' ')[1] === 'Novembre') month = 11;
        else { month = 12 };
        return new Date(+year, month - 1, +day);
    };

    // Select sort
    function sortToApply (content: any) {
        // Sort by String property ASCENDING (A - Z)
        if (filterMode === 0)
            return [...content].sort((a, b) => a.title > b.title ? 1 : -1);
        // Sort by String property DESCENDING (Z - A)
        else if (filterMode === 1)
            return [...content].sort((a, b) => a.title > b.title ? -1 : 1);
        // Sort by Numeric property ASCENDING (1 JANV - 31 JANV)
        else if (filterMode === 2)
            return [...content].sort((a, b) => convertStringToDate(a.date) > convertStringToDate(b.date) ? 1 : -1);
        // Sort by Numeric property DESCENDING (31 JANV - 1 JANV)
        else if (filterMode === 3)
            return [...content].sort((a, b) => convertStringToDate(b.date) < convertStringToDate(a.date) ? -1 : 1);
        // Sort by String property ASCENDING (A - Z)
        else {
            return [...content].sort((a, b) => a.title > b.title ? 1 : -1);
        }
    }

    return (
        <div onClick={() => { if (filter) setFilter(false) }} className='projects-container'>
            <div className='projects-title-filters'>
                <p className='projects-title'>Projects</p>
                <div className='projects-filters'>
                    <div className='projects-filters-categories'>
                        {categories.map((category, index) => (
                            <p key={index} className={index === mode ? 'projects-filters-category-bold' : ''} onClick={() => { setMode(index) }}>{category.name}</p>
                        ))}
                    </div>
                    <BsFilter onClick={() => { setFilter(!filter) }} className={filter ? 'projects-filters-logo-active' : 'projects-filters-logo'} />
                    {filter && (<div className='projects-filters-list'>
                        <p className='projects-filters-list-title'>Trier par :</p>
                        {filters.map((filt, index) => (
                            <div key={index} className="projects-filters-list-container">
                                <p onClick={() => { setFilter(false); setFilterMode(index); }} className='projects-filters-list-item'>{filt.name}</p>
                                {filterMode === index && (<BsCheck className='projects-filters-list-logo' />)}
                            </div>
                        ))}
                    </div>)}
                </div>
            </div>
            <div className='projects-cards' onClick={() => { setFilter(false) }}>
                {mode === 1 && (sortToApply(apps).map((app, index) => (
                    <AppCard
                        key={index}
                        position={index}
                        background={app.background}
                        logo={app.logo}
                        downloads={app.downloads}
                        languages={app.languages}
                        categories={app.categories}
                        score={app.score}
                        comments={app.comments}
                        released={app.released}
                        name={app.name}
                        description={app.description}
                        playstore_url={app.playstore_url}
                        version={app.version}
                        date={app.date}
                    />
                )))}
                {mode === 2 && (sortToApply(arts).map((art, index) => (
                    <ArtCard
                        key={index}
                        position={index}
                        picture={art.picture}
                        title={art.title}
                        instagram_link={art.instagram_link}
                        date={art.date}
                    />
                )))}
                {(mode === 0 || mode === 3) && (
                    <div className='projects-miss'>
                        <p>Aucun projet trouvé</p>
                    </div>
                )}
            </div>

        </div>
    );
};

export default Projects;