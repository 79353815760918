import React, { useEffect } from 'react';

import './Contact.scss';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='contact-container'>
            <p className='contact-title'>Contact</p>
            <div className='contact-miss'>
                <p>En cours de développement</p>
            </div>
        </div>
    );
};

export default Contact;