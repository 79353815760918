import React, { Component } from 'react';
import { BsInstagram } from "react-icons/bs";
import { motion } from 'framer-motion';

import './ArtCard.scss';

type MyProps = {
    picture: string,
    title: string,
    instagram_link: string,
    date: string,
    position: number;
};
class ArtCard extends Component<MyProps> {
    render() {
        return (
            <motion.div
                animate={{ opacity: [0, 1], x: [-20, 0], y: [-20, 0] }}
                transition={{ duration: 1, delay: this.props.position * 0.2 }}
                initial={{ opacity: 0 }}
                className='artcard-container'
            >
                <img className='artcard-background-picture' src={this.props.picture} alt='artcard-background' />
                <div className='artcard-details-container'>
                    <div className='artcard-third-width-left'>
                        <p className='artcard-date'>{this.props.date.split(' ')[0]} {this.props.date.split(' ')[1]}<br></br>{this.props.date.split(' ')[2]}</p>
                    </div>
                    <p className='artcard-title'>{this.props.title}</p>
                    <div className='artcard-third-width-right'>
                        <div className='artcard-button'>
                            <a href={this.props.instagram_link} target="_blank" rel="noreferrer">
                                <BsInstagram className='artcard-button-logo-instagram' />
                            </a>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }
}

export default ArtCard;