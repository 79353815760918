import React, { useState } from 'react';
import { Footer, Navbar } from './components';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';
import { About, Blog, Projects, Home, Mentions, Application, Error404, Contact } from './containers';
import './i18n';

function App() {

  const [theme, setTheme] = useState(window.localStorage.getItem('theme') || 'dark');

  window.addEventListener("themeHasChanged", changeThemeColor, false);
  function changeThemeColor() {
    setTheme(window.localStorage.getItem('theme'));
  }

  return (
    <div className='app' data-theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/about' element={<About />} />
          <Route path='/mentions' element={<Mentions />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/application/:name' element={<Application />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;