import React, { Component } from 'react';

import './AppCard.scss';
import { HiOutlineDownload, HiStar } from "react-icons/hi";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BsEye, BsCodeSlash } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { AiOutlineMessage } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

type MyProps = {
    background: string,
    logo: string,
    comments: string | undefined,
    downloads: string | undefined,
    score: string | undefined,
    languages: object,
    categories: object,
    name: string,
    description: string,
    released: boolean,
    playstore_url: string | undefined,
    version: string | undefined,
    date: string | undefined,
    position: number,
};

class AppCard extends Component<MyProps>{

    render() {
        return (
            <motion.div
                animate={{ opacity: [0, 1], x: [-20, 0], y: [-20, 0] }}
                transition={{ duration: 1, delay: this.props.position * 0.2 }}
                initial={{ opacity: 0 }}
                className='appcard-container'
            >
                <div className='appcard-background'>
                    <img className='appcard-background-picture' src={this.props.background} alt='appcard-background' />
                    {this.props.date && (<p className='appcard-release-date'>{this.props.date}</p>)}
                    {this.props.version && (<p className='appcard-release-version'>{this.props.version}</p>)}
                    <div className='appcard-shape-back'>
                        <img className='appcard-shape-back-logo' src={this.props.logo} alt='appcard-logo' />
                    </div>
                    <div className='appcard-stats'>
                        {this.props.comments && (<div className='appcard-stats-line'>
                            <p>{this.props.comments}</p>
                            <AiOutlineMessage className='appcard-logo' />
                        </div>)}
                        {this.props.downloads && (<div className='appcard-stats-line'>
                            <p>{this.props.downloads}</p>
                            <HiOutlineDownload className='appcard-logo' />
                        </div>)}
                        {this.props.score && (<div className='appcard-stats-line'>
                            <p>{this.props.score}</p>
                            <HiStar className='appcard-logo' />
                        </div>)}
                    </div>
                </div>
                <div className='appcard-text'>
                    <div className='appcard-specifications'>
                        <div className='appcard-spec-line'>
                            <p>{this.props.languages[0].name}</p>
                            <BsCodeSlash className='appcard-logo' />
                        </div>
                        <div className='appcard-spec-line'>
                            <p>{this.props.categories[0].name}</p>
                            <BiCategoryAlt className='appcard-logo' />
                        </div>
                    </div>
                    <p className='appcard-text-title'>{this.props.name}</p>
                    <p className='appcard-text-description'>{this.props.description}</p>
                    <div className='appcard-buttons'>
                        {this.props.released && (
                            <div className='appcard-button'>
                                <a href={this.props.playstore_url} target="_blank" rel="noreferrer">
                                    <IoLogoGooglePlaystore className='appcard-buttons-logo' />
                                </a>
                            </div>)}
                        {!this.props.released && (<p className='appcard-buttons-unavailable'>Unavailable</p>)}
                        <Link to={`/application/${this.props.name}`} className='appcard-button'>
                            <BsEye className='appcard-buttons-logo' />
                        </Link>
                    </div>
                </div>
            </motion.div >
        );
    }
}

export default AppCard;