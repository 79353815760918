import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import { BsFullscreen, BsFullscreenExit, BsFillMoonFill, BsFillSunFill, BsCheck } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { pictures } from '../../constants';

const languages = [
  {
    country: 'fr',
    flag: pictures.french_flag,
  },
  {
    country: 'en',
    flag: pictures.english_flag,
  },
  {
    country: 'ru',
    flag: pictures.russian_flag,
  },
  {
    country: 'es',
    flag: pictures.spainish_flag,
  },
]

const Navbar = () => {
  let number = -1;
  if (window.location.href === 'https://enguerranhuby.fr/' || window.location.href === 'https://enguerranhuby.fr/home')
    number = 0;
  else if (window.location.href === 'https://enguerranhuby.fr/projects')
    number = 1;
  else if (window.location.href === 'https://enguerranhuby.fr/blog')
    number = 2;
  else if (window.location.href === 'https://enguerranhuby.fr/about')
    number = 3;
  else {
    number = -1;
  }
  const { i18n } = useTranslation('home');
  const [category, setCategory] = useState(number);
  const [fullscreen, setFullscreen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 800) setToggleMenu(false);
    }
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    }
  }, [])

  const theme = window.localStorage.getItem('theme') || 'dark';

  const themeToggler = (the: string) => {
    window.localStorage.setItem('theme', the);
    window.dispatchEvent(new Event("themeHasChanged"));
  }


  const handleLanguageChange = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }

  return (
    <nav className="navbar" onClick={() => { if (filter) setFilter(false) }}>
      {!toggleMenu && (<div className='navbar-identity'>
        <p className='navbar-name'>Enguerran Huby</p>
        <p className='navbar-alias'>@Aroed</p>
      </div>)}
      {(toggleMenu || screenWidth > 800) && (<ul className='navbar-links'>
        {['home', 'projects', 'blog', 'about', 'contact'].map((item, index) => (
          <Link
            onClick={() => {
              setCategory(index);
              if (toggleMenu) setToggleMenu(false);
            }}
            to={`/${item}`}
            className={index === category ? "navbar-links-item navbar-links-item-bold" : "navbar-links-item"}
            key={index}
          >
            {item.charAt(0).toUpperCase() + item.slice(1)}
          </Link>
        ))}
        {screenWidth > 800 &&(<div className='navbar-language-container'>
          <div className={filter ? 'navbar-language navbar-language-active' : 'navbar-language'} onClick={() => { setFilter(!filter) }}>
            {languages.map((language, index) => (
              (currentLanguage === language.country && <img src={language.flag} className='navbar-language-flag' alt={'flagnavbar' + { index }} key={index} />)
            ))}
            <p className={filter ? 'navbar-language-text navbar-language-text-active' : 'navbar-language-text'}>{currentLanguage}</p>
            {!filter && (<HiChevronDown className='navbar-language-icon' />)}
            {filter && (<HiChevronUp className='navbar-language-icon navbar-language-icon-active' />)}
          </div>
          {filter && (<div className='navbar-languages-list-container'>
            {languages.map((language, index) => (<div
              className='navbar-languages-list'
              key={index}
              onClick={() => { setFilter(false); handleLanguageChange(language.country); }}
            >
              <img src={language.flag} className='navbar-languages-list-flag' alt={'flag' + { index }} />
              <p className='navbar-languages-list-text'>{language.country}</p>
              {currentLanguage === language.country && (<BsCheck className='navbar-languages-list-icon' />)}
            </div>))}
          </div>)}
        </div>)}

        {(screenWidth > 800 && theme === 'dark') && (<BsFillSunFill
          className='navbar-logo'
          onClick={() => { themeToggler('light') }}
        />)}
        {(screenWidth > 800 && theme === 'light') && (<BsFillMoonFill
          className='navbar-logo'
          onClick={() => { themeToggler('dark') }}
        />)}
        {(screenWidth > 800 && !fullscreen) && (<BsFullscreen
          className='navbar-logo'
          onClick={() => {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
          }}
        />)}
        {(screenWidth > 800 && fullscreen) && (<BsFullscreenExit
          className='navbar-logo'
          onClick={() => {
            document.exitFullscreen();
            setFullscreen(false);
          }}
        />)}
      </ul>)}
      {(screenWidth <= 800 && toggleMenu && theme === 'dark') && (<BsFillSunFill
        className='navbar-logo-resp-left-bis'
        onClick={() => { themeToggler('light') }}
      />)}
      {(screenWidth <= 800 && toggleMenu && theme === 'light') && (<BsFillMoonFill
        className='navbar-logo-resp-left-bis'
        onClick={() => { themeToggler('dark') }}
      />)}
      {(screenWidth <= 800 && !fullscreen && toggleMenu) && (<BsFullscreen
        className='navbar-logo-resp-left'
        onClick={() => {
          document.documentElement.requestFullscreen();
          setFullscreen(true);
        }}
      />)}
      {(screenWidth <= 800 && fullscreen && toggleMenu) && (<BsFullscreenExit
        className='navbar-logo-resp-left'
        onClick={() => {
          document.exitFullscreen();
          setFullscreen(false);
        }}
      />)}
      {(screenWidth <= 800 && toggleMenu) &&(<div className='navbar-language-container navbar-language-container-resp'>
          <div className={filter ? 'navbar-language navbar-language-active' : 'navbar-language'} onClick={() => { setFilter(!filter) }}>
            {languages.map((language, index) => (
              (currentLanguage === language.country && <img src={language.flag} className='navbar-language-flag' alt={'flagnavbar' + { index }} key={index} />)
            ))}
            <p className={filter ? 'navbar-language-text navbar-language-text-active' : 'navbar-language-text'}>{currentLanguage}</p>
            {!filter && (<HiChevronDown className='navbar-language-icon' />)}
            {filter && (<HiChevronUp className='navbar-language-icon navbar-language-icon-active' />)}
          </div>
          {filter && (<div className='navbar-languages-list-container'>
            {languages.map((language, index) => (<div
              className='navbar-languages-list'
              key={index}
              onClick={() => { setFilter(false); handleLanguageChange(language.country); }}
            >
              <img src={language.flag} className='navbar-languages-list-flag' alt={'flag' + { index }} />
              <p className='navbar-languages-list-text'>{language.country}</p>
              {currentLanguage === language.country && (<BsCheck className='navbar-languages-list-icon' />)}
            </div>))}
          </div>)}
        </div>)}
      {!toggleMenu && (<BiMenu onClick={() => { setToggleMenu(!toggleMenu) }} className="navbar-menu-logo" />)}
      {toggleMenu && (<IoClose onClick={() => { setToggleMenu(!toggleMenu) }} className="navbar-menu-logo" />)}
    </nav>
  );
};

export default Navbar;